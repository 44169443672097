import "./NavbarStyles.css";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

export default function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <header className="header">
      <div>
        <a className="link-no-underline" href="/">
          Siqi Chen
        </a>
      </div>
      <nav className={click ? "main-nav active" : "main-nav"}>
        <li>
          <a className="main-nav-list" href="/Portfolio">
            Portfolio
          </a>
        </li>
        {/* <li>
          <a className="main-nav-list" href="/Resume">
            Resume
          </a>
        </li> */}
        <li>
          <a className="main-nav-list" href="/About">
            About
          </a>
        </li>
      </nav>
      <button className="btn-mobile-nav" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#000" }} />
        ) : (
          <FaBars size={20} style={{ color: "#000" }} />
        )}
      </button>
    </header>
  );
}
